<template>
  <v-card class="mt-2">
    <v-card-title>
      <div class="pt-3 pl-2">{{ $t("track.newIssue") }}</div>
    </v-card-title>
    <v-card-text>
      <v-divider></v-divider>
      <issue-inputs
        @save="createIssue($event)"
        @cancel="goToRequirementList()"
        :disabled="saveDisabled"
      ></issue-inputs>
    </v-card-text>
  </v-card>
</template>

<script>
import IssueInputs from "../components/tracking/IssueInputs.vue";
export default {
  props: ["saveDisabled"],
  methods: {
    async createIssue(issue) {
      await this.drivers.client.post("track/issue/create", {
        json: issue
      });
      this.goToRequirementList();
    },
    goToRequirementList() {
      this.$router.push({ name: "Requirements" });
    }
  },
  components: { IssueInputs }
};
</script>
