<template>
  <div>
    <v-card-text>
      <div id="issue-title" class="px-5 py-3 border rounded">
        <h5>{{ $t("title") }}</h5>
        <text-input
          id="issue-title"
          v-model="title"
          label="Ex: Administrator should be able to freeze all patients of a given site"
        ></text-input>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="px-5 py-3 border rounded">
        <h5>Type</h5>
        <v-radio-group id="issue-label" v-model="label" row>
          <v-radio
            :label="$t('requirement')"
            color="info"
            value="proposal"
          ></v-radio>
          <v-radio label="Bug" color="red" value="bug"></v-radio>
        </v-radio-group>
      </div>
    </v-card-text>
    <v-card-text>
      <div class="px-5 py-3 border rounded">
        <h5>Description</h5>
        <v-textarea id="issue-body" v-model="body"></v-textarea>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <div class="my-3 text-right">
      <action-panel
        @action="onAction($event.type)"
        :saveDisabled="!(title && body)"
      ></action-panel>
    </div>
  </div>
</template>

<script>
import ActionPanel from "../admin/ActionPanelAdmin.vue";
import TextInput from "../input/TextInput.vue";

import { mapGetters } from "vuex";

export default {
  components: { TextInput, ActionPanel },
  data() {
    return {
      title: undefined,
      body: undefined,
      label: "proposal"
    };
  },
  computed: {
    ...mapGetters("auth", ["email"]),
    issue() {
      const bodyWithUserInfo = `This issue was created by ${this.email} from the Quality Desk.\n\n${this.body}`;
      return { title: this.title, body: bodyWithUserInfo, label: this.label };
    }
  },
  methods: {
    async onAction(event) {
      switch (event) {
        case "save":
          this.$emit("save", this.issue);
          break;
        case "cancel":
          this.$emit("cancel");
          break;
      }
    }
  },
  i18n: {
    messages: {
      en: {
        title: "Title",
        requirement: "Requirement"
      },
      fr: {
        title: "Titre",
        requirement: "Exigence"
      }
    }
  }
};
</script>

<style></style>
